export default ({
    url: 'https://api.altoida.com',
    routes: {
        auth: {
            login: {
                url: '/auth/login',
                method: 'POST'
            },
            refresh: {
                url: '/auth/refresh',
                method: 'POST'
            },
            activate: {
                url: '/auth/activate/:activateToken?',
                method: 'post'
            },
            requestNewCodeActivation: {
                url: '/auth/generate/activate',
                method: 'POST'
            },
            forgotPassword: {
                url: '/auth/forgot_password',
                method: 'POST'
            },
            resetPassword: {
                url: '/auth/reset_password/:resetToken',
                method: 'POST'
            }
        },
        fitbit: {
            authenticate: {
                url: '/fitbit/:testSubjectId',
                method: 'GET'
            },
            callback: {
                url: '/fitbit/:testSubjectId',
                method: 'GET'
            }
        },
        user: {
            changePassword: {
                url: '/auth/change-password',
                method: 'POST'
            },
            profile: {
                url: '/users/:userId',
                method: 'get'
            }
        }
    }
});
