import i18n from 'i18next';
import moment from 'moment';
import {initReactI18next} from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import titleCase from 'lodash/startCase';
import Config from 'common/Config';

let apiPath = `${Config.get('locale.url', '/').replace(/\/$/, '')}/locales/{{lng}}/{{ns}}${Config.get('locale.extension', '.json')}`;

i18n
    .use(Backend)
    // .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        backend: {
            loadPath: apiPath,
            allowMultiLoading: true,
            crossDomain: true,
            requestOptions: {
                // cache: 'default'
            }
        },
        detection: {
            caches: ['localStorage', 'cookie'],
            excludeCacheFor: ['cimode']
        },
        ns: ['common', 'messages', 'errors'],
        lang: 'en',
        fallbackLng: 'en',
        load: 'currentOnly',
        keySeparator: false,
        interpolation: {
            escapeValue: false, // react already safes from xss
            format: function (value, format, lng) {
                if (format === 'uppercase') {
                    return value.toUpperCase();
                }
                if (format === 'titlecase') {
                    return titleCase(value);
                }
                if (value instanceof Date) {
                    return moment(value).format(format);
                }
                return value;
            }
        }
    });

export default i18n;
