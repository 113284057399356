import {createSelector} from 'reselect';
import get from 'lodash/get';
import last from 'lodash/last';

const emptyArray = [];
const emptyObject = {};

const getSidebarId = (state, id) => id || null;

const getUIState = state => get(state, 'ui', null);

const getRootStateUI = createSelector(
    getUIState,
    ui => ui || emptyObject
);


const UISelectorFactory = getUI => ({
    getUI,
    getTheme: createSelector(getUI, ui => get(ui, 'theme', 'light')),
    getSidebars: createSelector(getUI, ui => get(ui, 'sidebars', emptyObject)),
    getSidebarById: createSelector(
        getUI,
        getSidebarId,
        (ui, id) => ui && id && get(ui.sidebars, id, false)
    ),
    getUIMainSidebar: createSelector(getUI, ui => get(ui, 'sidebars.main', true)),
    getUISecondarySidebar: createSelector(getUI, ui => get(ui, 'sidebars.secondary', false)),
    getUIRightSidebar: createSelector(getUI, ui => get(ui, 'sidebars.right', false)),
    getHasNotification: createSelector(getUI, ui => Boolean(Object.keys(get(ui, 'notifications', emptyObject)).length)),
    getNotifications: createSelector(getUI, ui => get(ui, 'notifications', emptyObject)),
    getWizard: createSelector(getUI, ui => get(ui, 'wizard', emptyObject)),
    getWizardStep: createSelector(getUI, ui => get(ui, 'wizard.currentStep', 0)),
    getWizardData: createSelector(getUI, ui => get(ui, 'wizard.data', 0)),
    getHasAlert: createSelector(getUI, ui => Boolean(get(ui, 'alert', false))),
    getAlert: createSelector(getUI, ui => get(ui, 'alert', null)),
    getLazyDialog: createSelector(getUI, ui => get(ui, 'dialog', null)),
    getLoading: createSelector(getUI, ui => get(last(get(ui, 'loading', emptyArray)) || emptyArray, 'message', null))
});

export default UISelectorFactory(getRootStateUI);
