import {
    CLEAR_TOKEN,
    CLOSE_WELCOME,
    LOGIN_ERROR,
    LOGOUT,
    REFRESH_TOKEN,
    REFRESHING_TOKEN,
    SET_TOKEN,
    SET_CURRENT_USER
} from 'store/auth/AuthActionTypes';

export const initialState = {
    status: 'unauthenticated',
    error: null
};

export default function auth(state = initialState, action) {
    let newState = {};
    switch (action.type) {
        case SET_CURRENT_USER:
            newState = {
                ...state,
                userId: action.userId
            };
            break;
        case SET_TOKEN:
            newState = {
                ...state,
                ...initialState,
                isRefreshing: false,
                status: 'authenticated',
                ...action.token
            };
            break;
        case CLOSE_WELCOME:
            newState = {
                ...state,
                welcome: null
            };
            break;
        case CLEAR_TOKEN:
            newState = {
                ...initialState
            };
            break;
        case REFRESH_TOKEN:
            newState = {
                ...state,
                ...initialState,
                isRefreshing: false,
                ...action.token
            };
            break;
        case REFRESHING_TOKEN:
            newState = {
                ...state,
                isRefreshing: action.isRefreshing
            };
            break;
        case LOGIN_ERROR:
            newState = {
                ...initialState,
                error: action.error
            };
            break;
        case LOGOUT:
            newState = {
                ...state
            };
            break;
        default :
            newState = state;
            break;
    }
    return newState;
}
