export default ({
    base: {
        path: '/',
        login: {
            path: '/login'
        },
        forgotPassword: {
            path: '/forgot-password'
        },
        resetPassword: {
            path: '/reset-password/:code?'
        },
        changePassword: {
            path: '/change-password'
        },
        unlockAccount: {
            path: '/unlock-account/:code?'
        },
        confirmAccount: {
            path: '/confirm-account/:code?'
        },
        requestNewCode: {
            path: '/request-new-code'
        },
        modules: {}
    }
});
