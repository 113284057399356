import {combineReducers} from 'redux';
import {reducer as reduxFormReducer} from 'redux-form';
import {connectRouter} from 'connected-react-router';
import auth from 'store/auth/AuthReducer';
import user from 'store/user/UserReducer';
import ui from 'store/ui/UIReducer';

const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    ui,
    user,
    auth,
    form: reduxFormReducer
});

export default createRootReducer;
