import Config from 'common/Config';
import {createBrowserHistory, createMemoryHistory} from 'history';
import {applyMiddleware, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import createRootReducer from 'store/root/rootReducers';
import {routerMiddleware} from 'connected-react-router';

export const history = process.env.REACT_APP_ENV === 'test' ? createMemoryHistory() : createBrowserHistory();

const preloadedState = {};
const enhancers = [];

/* istanbul ignore next */
if (Config.get('env') === 'local' || Config.get('NODE_ENV') === 'development' || process.env.REACT_APP_ENV === 'local') {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

    if (typeof devToolsExtension === 'function') {
        enhancers.push(devToolsExtension());
    }
}

const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    compose(
        applyMiddleware(
            routerMiddleware(history),
            thunk
            // createLogger()
        ),
        ...enhancers
    )
);

export default store;
