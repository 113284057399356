/**
 * User Reducer definitions
 */

import {LOGOUT} from 'store/auth/AuthActionTypes';
import keyBy from 'lodash/keyBy';
import deepmerge from 'deepmerge';
import kebabCase from 'lodash/kebabCase';
import {
    CLEAR_USERS,
    GET_USERS_REQUEST,
    GET_USERS_ERROR,
    GET_USERS_SUCCESS,
    CLEAR_USER,
    GET_USER_REQUEST,
    GET_USER_ERROR,
    GET_USER_SUCCESS,
    UPDATE_USER_REQUEST,
    UPDATE_USER_ERROR,
    UPDATE_USER_SUCCESS,
    CREATE_USER_REQUEST,
    CREATE_USER_ERROR,
    CREATE_USER_SUCCESS,
    DELETE_USER_REQUEST,
    DELETE_USER_ERROR,
    DELETE_USER_SUCCESS,
    ADD_USER_NOTIFICATION,
    DISMISS_USER_NOTIFICATION
} from 'store/user/UserActionTypes';

const initialState = {
    data: {},
    status: 'idle',
    error: null
};

export default function Users(state = initialState, action) {

    let newState = {};

    switch (action.type) {
        case CLEAR_USERS: {
            newState = {
                ...initialState
            };
            break;
        }
        case GET_USERS_REQUEST: {
            newState = {
                ...state,
                status: action.status,
                error: null
            };
            break;
        }
        case GET_USERS_SUCCESS: {
            const users = keyBy(action.data, 'id');
            newState = {
                ...initialState,
                status: action.status,
                data: {
                    ...deepmerge(state.data, users)
                },
                error: null
            };
            break;
        }
        case GET_USERS_ERROR: {
            newState = {
                ...initialState,
                error: action.error,
                status: action.status
            };
            break;
        }
        case CLEAR_USER: {
            const {[action.id]: removed, ...newData} = state.data;
            newState = {
                ...state,
                data: {...newData},
                status: 'idle',
                error: null
            };
            break;
        }
        case GET_USER_REQUEST:
        case UPDATE_USER_REQUEST:
        case CREATE_USER_REQUEST:
        case DELETE_USER_REQUEST: {
            const newData = {...state.data};
            newState = {
                ...state,
                data: {...newData},
                status: action.status,
                error: null
            };
            break;
        }
        case GET_USER_SUCCESS:
        case UPDATE_USER_SUCCESS:
        case CREATE_USER_SUCCESS: {
            const newData = {...state.data};
            const user = deepmerge(newData[action.id], action.data);
            Object.assign(newData, {[action.id]: user});
            newState = {
                ...state,
                status: action.status,
                data: {...newData},
                error: null
            };
            break;
        }
        case GET_USER_ERROR:
        case UPDATE_USER_ERROR:
        case CREATE_USER_ERROR:
        case DELETE_USER_ERROR: {
            newState = {
                ...state,
                status: action.status,
                error: action.error
            };
            break;
        }
        case DELETE_USER_SUCCESS: {
            const {[action.id]: removed, ...newData} = state.data;
            newState = {
                ...state,
                data: {...newData},
                status: action.status,
                error: null
            };
            break;
        }
        case LOGOUT:
            newState = {
                ...initialState
            };
            break;
        case ADD_USER_NOTIFICATION: {
            const newData = {...state.data};
            Object.assign(newData, {
                [action.userId]: {
                    ...state.data[action.userId],
                    notifications: {
                        [action.notification.id || kebabCase(action.notification.text)]: {
                            id: action.notification.id || kebabCase(action.notification.text),
                            ...action.notification
                        },
                        ...state.data[action.userId].notifications
                    }
                }
            });
            newState = {
                ...state,
                status: action.status,
                data: {...newData},
                error: null
            };
            break;
        }
        case DISMISS_USER_NOTIFICATION: {
            const newData = {...state.data};
            delete newData[action.userId].notifications[action.id];
            newState = {
                ...state,
                data: {...newData}
            };
            break;
        }
        default:
            newState = state;
            break;
    }
    return newState;
}
