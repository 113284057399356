import {
    CLOSE_ALERT,
    CLOSE_LAZY_DIALOG,
    CLOSE_NOTIFICATION,
    HIDE_LOADING,
    HIDE_SIDEBAR,
    RESET_WIZARD,
    SET_WIZARD,
    SHOW_ALERT,
    SHOW_LAZY_DIALOG,
    SHOW_LOADING,
    SHOW_NOTIFICATION,
    SHOW_SIDEBAR,
    TOGGLE_THEME
} from 'store/ui/UIActionTypes';
import random from 'lodash/random';

const initialState = {
    sidebars: {
        main: false,
        secondary: false,
        right: false
    },
    notifications: {},
    wizard: {
        currentStep: 0,
        data: null
    },
    alert: null,
    loading: [],
    theme: 'light',
    dialog: null
};

export default function ui(state = initialState, action) {
    let sidebars = Object.assign({}, state.sidebars);
    switch (action.type) {
        case TOGGLE_THEME:
            return {
                ...state,
                theme: action.theme
            };
        case SHOW_SIDEBAR:
            sidebars[action.sidebar] = true;
            return {...state, sidebars};
        case HIDE_SIDEBAR:
            sidebars[action.sidebar] = false;
            return {...state, sidebars};
        case SHOW_NOTIFICATION:
            return {
                ...state,
                notifications: Object.assign({}, state.notifications, {[random(0, 9999999)]: action.notification})
            };
        case CLOSE_NOTIFICATION: {
            const notifications = {...state.notifications};
            delete notifications[action.index];
            return {...state, notifications: notifications};
        }
        case SET_WIZARD:
            return {...state, wizard: {...state.wizard, ...action.data}};

        case RESET_WIZARD:
            return {...state, wizard: {...initialState.wizard}};
        case SHOW_ALERT:
            return {
                ...state,
                alert: {...action.data}
            };
        case CLOSE_ALERT:
            return {
                ...state,
                alert: null
            };
        case SHOW_LOADING:
            return {
                ...state,
                loading: [
                    ...state.loading,
                    action.data
                ]
            };
        case HIDE_LOADING:
            return {
                ...state,
                loading: [...state.loading.filter(item => item.type !== action.data)]
            };
        case SHOW_LAZY_DIALOG:
            return {
                ...state,
                dialog: {
                    ...action.data
                }
            };
        case CLOSE_LAZY_DIALOG:
            return {
                ...state,
                dialog: null
            };
        default:
            return state;
    }
}
