export const SHOW_SIDEBAR = 'ui/sidebar/show';
export const HIDE_SIDEBAR = 'ui/sidebar/hide';

export const SHOW_NOTIFICATION = 'ui/notification/show';
export const CLOSE_NOTIFICATION = 'ui/notification/close';

export const SET_WIZARD = 'ui/wizard/set';
export const RESET_WIZARD = 'ui/wizard/reset';

export const SHOW_ALERT = 'ui/alert/show';
export const CLOSE_ALERT = 'ui/alert/close';

export const SHOW_LOADING = 'ui/loading/show';
export const HIDE_LOADING = 'ui/loading/hide';

export const SHOW_LAZY_DIALOG = 'ui/dialog/lazy/open';
export const CLOSE_LAZY_DIALOG = 'ui/dialog/lazy/close';

export const TOGGLE_THEME = 'ui/theme/toggle';
