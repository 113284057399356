import {createMuiTheme} from '@material-ui/core';

const rawTheme = createMuiTheme();

const palette = (theme) => ({
    type: theme,
    primary: rawTheme.palette.augmentColor({
        contrastText: '#fff',
        main: '#48ddae'
    }),
    secondary: rawTheme.palette.augmentColor({
        contrastText: '#fff',
        main: '#c9c6e1'
    }),
    error: rawTheme.palette.augmentColor({
        main: '#f44336',
        contrastText: '#fff'
    }),
    success: rawTheme.palette.augmentColor({
        main: '#689f38',
        contrastText: '#fff'
    }),
    warning: rawTheme.palette.augmentColor({
        main: '#f57c00',
        contrastText: '#fff'
    }),
    info: rawTheme.palette.augmentColor({
        main: '#42a5f5',
        contrastText: '#fff'
    })
});

const themes = theme => createMuiTheme({
    palette: palette(theme),
    overrides: {
        MuiCssBaseline: {
            '@global': {
                'html, body': {
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                },
                html: {
                    fontFamily: 'sans-serif',
                    lineHeight: 1.15,
                    webkitTextSizeAdjust: '100%',
                    webkitTapHighlightColor: 'transparent'
                },
                body: {
                    margin: 0,
                    fontSize: '1rem',
                    fontWeight: 400,
                    lineHeight: 1.5,
                    color: '#515151',
                    textAlign: 'left'
                }
            }
        },
        MuiListItemIcon: {
            root: {
                paddingRight: 16,
                paddingLeft: 8,
                minWidth: 'auto'
            }
        },
        MuiButton: {
            root: {
                whiteSpace: 'nowrap'
            }
        },
        MuiIconButton: {
            root: {
                '&.nofocus': {
                    '&:hover': {
                        backgroundColor: 'transparent',
                        transition: 'color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                        color: theme === 'light' ? 'rgba(0, 0, 0, 0.8)' : 'rgba(255, 255, 255, 0.8)'
                    }
                }
            }
        },
        MuiListItem: {
            secondaryAction: {
                paddingRight: 70
            }
        },
        MuiTooltip: {
            tooltip: {
                backgroundColor: '#fff',
                color: 'rgba(0, 0, 0, 0.87)',
                boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
                fontSize: '1em'
            },
            arrow: {
                color: '#fff'
            }
        },
        MuiCardHeader: {
            title: {
                fontSize: '1.2em'
            }
        },
        MuiAlert: {
            root: {
                margin: '8px 0 8px 0'
            },
            outlinedError: {
                background: '#ffffff'
            },
            outlinedSuccess: {
                background: '#ffffff'
            },
            outlinedWarning: {
                background: '#ffffff'
            },
            outlinedInfo: {
                background: '#ffffff'
            }
        }
    }

});

export default themes;
