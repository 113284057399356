import {CssBaseline, MuiThemeProvider} from '@material-ui/core';
import Config from 'common/Config';
import themes from 'common/themes';
import {ConnectedRouter} from 'connected-react-router';
import PropTypes from 'prop-types';
import React from 'react';
import {connect, Provider} from 'react-redux';
import store, {history} from 'store';
import UISelectors from 'store/ui/UISelectors';
import Preloader from 'views/components/Preloader';

const Main = React.lazy(() => import('views/pages/Main'));

function App(props) {
    return (
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <ConnectedSubmodules/>
            </ConnectedRouter>
        </Provider>
    );
}

const SubModules = (props) => {

    document.title = Config.get('app.title');
    document.body.classList.remove('theme-light');
    document.body.classList.remove('theme-dark');
    document.body.classList.add(`theme-${props.theme}`);
    return (
        <MuiThemeProvider theme={themes(props.theme)}>
            <CssBaseline/>
            <React.Suspense fallback={<Preloader/>}>
                <Main {...props}/>
            </React.Suspense>
        </MuiThemeProvider>
    );
};

SubModules.propTypes = {
    theme: PropTypes.string
};

const ConnectedSubmodules = connect((state) => ({
    theme: UISelectors.getTheme(state)
}))(SubModules);

export default App;
