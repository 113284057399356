import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import App from 'views/App';
import i18next from 'core/i18next';
import 'styles/global.css';
ReactDOM.render(<App/>, document.getElementById('root'));

if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test') {
    reportWebVitals(console.log);
}
